import React from "react";
import { AboutUs, ComingSoon, HeroSection, Navbar } from "../../components";
import Features from "../../components/Landing/Features";
import Banner from "../../components/Landing/Banner";
import Footer from "../../components/Footer/Footer";
import Services from "../../components/Landing/Services";

const Landing = () => {
  return (
    <>
      <div className="navbar shadow-md sticky top-0 overflow-hidden z-20 bg-white">
        <div style={{ maxWidth: "1350px", margin: "0 auto" }}>
          <Navbar />
        </div>
      </div>
      <div
        className="px-3 sm:px-10 overflow-hidden"
        style={{ maxWidth: "1400px", margin: "0 auto" }}
      >
        <div className=" flex flex-col">
          <div className="px-4 XL:px-6 Xl:px-10 py-4">
            <HeroSection />
          </div>
        </div>
        <div className="mt-28">
          <ComingSoon />
        </div>
        <div className="px-4 XL:px-6 Xl:px-10">
          <AboutUs />
        </div>
        <div className="px-4 XL:px-6 Xl:px-10">
          <Services />
        </div>
        <div className="px-4 XL:px-6 Xl:px-10">
          <Features />
        </div>
        <div className="px-4 XL:px-6 Xl:px-10 py-4 my-8">
          <Banner />
        </div>
        <div className="px-4 XL:px-6 Xl:px-10">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Landing;