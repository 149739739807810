import React, { useEffect } from "react";
import { Navbar } from "../../components";
import Footer from "../../components/Footer/Footer";
import AOS from "aos";

const Privacy = () => {
  useEffect(() => {
    AOS.init({ duration: 600 });
    // Scroll to top when component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="navbar shadow-md sticky top-0 overflow-hidden z-20">
        <div style={{ maxWidth: "1350px", margin: "0 auto" }}>
          <Navbar />
        </div>
      </div>
      <div
        className="px-3 sm:px-10"
        style={{ maxWidth: "1200px", margin: "0 auto" }}
      >
        <div className="my-8 max-w-4xl mx-auto">
          <h1 className="text-4xl font-mazzardbold mb-6" data-aos="fade-down">
            Privacy Policy
          </h1>
          <div className="space-y-6 text-gray-700">
            <p className="text-lg" data-aos="fade-up" data-aos-delay="100">
              Fusion Cab ("we," "our," or "us") is committed to safeguarding the
              privacy of our users ("you" or "your"). This Privacy Policy
              describes how we collect, use, disclose, and protect your personal
              information when you access or use our services.
            </p>

            <section
              className="space-y-4"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <h2 className="text-2xl font-mazzardbold mt-8">
                1. Information We Collect
              </h2>
              <p>
                We collect various types of information in connection with our
                Services, including:
              </p>
              <ul className="list-disc pl-6 space-y-2">
                <li>
                  <span className="font-semibold">Personal Information:</span>{" "}
                  When you register for an account, book a cab, or contact us,
                  we may collect personal information such as your name, email
                  address, phone number, postal address, and payment details.
                </li>
                <li>
                  <span className="font-semibold">Ride Information:</span> This
                  includes details about your bookings, such as pickup and
                  drop-off locations, ride preferences, trip duration, and any
                  special requests.
                </li>
                <li>
                  <span className="font-semibold">Location Data:</span> We
                  collect location information from your device when you use our
                  Services to facilitate pickups and drops, calculate fares, and
                  offer location-based services. You may enable or disable
                  location services at any time through your mobile device
                  settings.
                </li>
                <li>
                  <span className="font-semibold">Usage Data:</span> We gather
                  information about your interactions with our Services,
                  including your IP address, device identifiers, browser type,
                  pages visited, and the time and date of each visit.
                </li>
                <li>
                  <span className="font-semibold">
                    Cookies and Similar Technologies:
                  </span>{" "}
                  We use cookies and similar technologies to collect information
                  about your interaction with our Services, enhance user
                  experience, and provide personalized content.
                </li>
              </ul>
            </section>
            <section
              className="space-y-4"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <h2 className="text-2xl font-mazzardbold mt-8">
                2. How We Use Your Information
              </h2>
              <p>
                We use the information we collect for the following purposes:
              </p>
              <ul className="list-disc pl-6 space-y-2">
                <li>
                  <span className="font-semibold">
                    To Provide and Manage Our Services:
                  </span>{" "}
                  This includes processing bookings for intercity travel,
                  airport transfers, and hourly rentals, managing your account,
                  and processing payments.
                </li>
                <li>
                  <span className="font-semibold">
                    To Enhance User Experience:
                  </span>{" "}
                  We use ride history, preferences, and feedback to improve our
                  services and offer personalized features such as suggested
                  routes, saved locations, and preferred vehicle types.
                </li>
                <li>
                  <span className="font-semibold">
                    To Communicate with You:
                  </span>{" "}
                  We may use your contact information to send you booking
                  confirmations, ride status updates, promotional offers, and
                  respond to your inquiries.
                </li>
                <li>
                  <span className="font-semibold">
                    To Improve and Develop Our Services:
                  </span>{" "}
                  Analyzing usage patterns helps us understand how our services
                  are used and identify areas for improvement, such as
                  optimizing our tech platform for better connectivity and
                  performance.
                </li>
                <li>
                  <span className="font-semibold">
                    To Ensure Safety and Security:
                  </span>{" "}
                  Your information helps us maintain a safe environment for all
                  users by conducting driver background checks, verifying user
                  identities, and preventing fraudulent activities.
                </li>
                <li>
                  <span className="font-semibold">
                    To Comply with Legal Requirements:
                  </span>{" "}
                  We may use your information to comply with applicable laws,
                  regulations, and industry standards, and to enforce our terms
                  and conditions.
                </li>
              </ul>
            </section>

            <section className="mt-8" data-aos="fade-up" data-aos-delay="400">
              <h2 className="text-2xl font-mazzardbold">
                3. How We Share Your Information
              </h2>
              <p className="mt-4">
                We may share your personal information in the following
                scenarios:
              </p>
              <ul className="list-disc pl-6 space-y-2">
                <li>
                  <span className="font-semibold">
                    With Service Providers and Partners:
                  </span>{" "}
                  We share your information with third-party service providers,
                  including payment processors, customer support partners, and
                  data analytics providers, who assist us in delivering our
                  Services.
                </li>
                <li>
                  <span className="font-semibold">With Drivers:</span> We
                  provide drivers with the necessary information to complete
                  your booking, such as your name, contact number, and
                  pickup/drop-off locations.
                </li>
                <li>
                  <span className="font-semibold">
                    For Legal and Safety Purposes:
                  </span>{" "}
                  We may disclose your information to law enforcement agencies,
                  regulators, or other parties if required by law or to protect
                  the rights, property, or safety of Fusion Cab, our users, or
                  others.
                </li>
                <li>
                  <span className="font-semibold">Business Transfers:</span> If
                  Fusion Cab is involved in a merger, acquisition, or asset
                  sale, your information may be transferred as part of that
                  transaction.
                </li>
                <li>
                  <span className="font-semibold">With Your Consent:</span> We
                  may share your information for other purposes with your
                  explicit consent.
                </li>
              </ul>
            </section>

            <section className="mt-8" data-aos="fade-up" data-aos-delay="500">
              <h2 className="text-2xl font-mazzardbold">
                4. Your Privacy Choices and Rights
              </h2>
              <p className="mt-4">
                Depending on your location, you may have the following rights
                concerning your personal information:
              </p>
              <ul className="list-disc pl-6 space-y-2">
                <li>
                  <span className="font-semibold">Access and Update:</span> You
                  can access and update your personal information through your
                  account settings or by contacting us directly.
                </li>
                <li>
                  <span className="font-semibold">Deletion:</span> You may
                  request the deletion of your personal information, subject to
                  certain legal or contractual restrictions.
                </li>
                <li>
                  <span className="font-semibold">Opt-Out:</span> You have the
                  right to opt-out of receiving promotional communications from
                  us. However, you will continue to receive service-related
                  communications necessary for the use of our Services.
                </li>
                <li>
                  <span className="font-semibold">Location Services:</span> You
                  can manage your device’s location settings to control whether
                  you share your location data with us.
                </li>
              </ul>
              <p className="mt-4">
                To exercise these rights, please contact us at{" "}
                <a href="mailto:info@fusioncab.com" className="text-blue-600">
                  info@fusioncab.com
                </a>
              </p>
            </section>
            <section className="mt-8" data-aos="fade-up" data-aos-delay="600">
              <h2 className="text-2xl font-mazzardbold">5. Data Security</h2>
              <p className="mt-4">
                We implement appropriate technical and organizational measures
                to protect your personal information from unauthorized access,
                use, or disclosure. However, no method of transmission over the
                internet or method of electronic storage is completely secure.
              </p>
            </section>

            <section className="mt-8" data-aos="fade-up" data-aos-delay="700">
              <h2 className="text-2xl font-mazzardbold">
                6. Children’s Privacy
              </h2>
              <p className="mt-4">
                Our Services are not intended for children under 13 years of
                age, and we do not knowingly collect personal information from
                children under 13. If you become aware that a child has provided
                us with personal information, please contact us immediately.
              </p>
            </section>

            <section className="mt-8" data-aos="fade-up" data-aos-delay="800">
              <h2 className="text-2xl font-mazzardbold">
                7. Third-Party Services
              </h2>
              <p className="mt-4">
                Our Services may contain links to third-party websites,
                applications, or services not operated by us. We are not
                responsible for the privacy practices of these third parties. We
                encourage you to review the privacy policies of any third-party
                services you access.
              </p>
            </section>

            <section className="mt-8" data-aos="fade-up" data-aos-delay="900">
              <h2 className="text-2xl font-mazzardbold">
                8. Changes to This Privacy Policy
              </h2>
              <p className="mt-4">
                We may update this Privacy Policy periodically to reflect
                changes in our practices or for other operational, legal, or
                regulatory reasons. Any changes will be posted on this page, and
                the updated date will be indicated at the top of this policy.
              </p>
            </section>
            <section className="mt-8" data-aos="fade-up" data-aos-delay="1000">
              <h2 className="text-2xl font-mazzardbold">9. Contact Us</h2>
              <p className="mt-4">
                If you have any questions or concerns about this Privacy Policy
                or our data practices, please contact us at:
              </p>
              <div className="mt-4">
                <p className="font-semibold">Fusion Cab</p>
                <p>SNR Fleet Services Private Limited</p>
                <p>29, Sai Sannadhi, Pragathi Enclave, Miyapur,</p>
                <p>Hyderabad, TS – 49</p>
                <p>
                  <a href="mailto:info@fusioncab.com" className="text-blue-600">
                    info@fusioncab.com
                  </a>
                </p>
                <p>
                  <a href="tel:+918309412828" className="text-blue-600">
                    +91 8309412828
                  </a>
                </p>
              </div>
            </section>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Privacy;
