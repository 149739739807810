import React, { useEffect } from "react";
import features from "../../constants/features";
import "aos/dist/aos.css";
import AOS from "aos";

const Features = () => {
   useEffect(() => {
     AOS.init({ duration: 1000 });
   }, []);
  return (
    <div className="w-full h-full flex box-border p-4">
      <div className="w-full h-full flex flex-col items-center justify-center">
        <div className="w-full text-center mb-8">
          <div className="text-3xl md:text-5xl mt-5  text-center font-mazzardbold">
            Features
          </div>
        </div>
        <div
          className="box-border p-4 flex justify-center flex-wrap gap-x-10 gap-y-5"
          style={{
            maxWidth: "1000px",
            width: "100%",
            height: "auto",
          }}
        >
          {features.map((feature, index) => (
            <div
              key={index}
              data-aos="fade-up"
              data-aos-delay={index * 150}
              style={{
              width: "200px",
              height: "auto",
              }}
            >
              <div
                className={`${
                  feature.color === "blue" ? "bg-[#6B8DEF]" : "bg-[#14161A]"
                } w-full shadow-md rounded-2xl p-4 flex justify-center items-center aspect-square mb-2`}
                style={{
                  width: "200px",
                  height: "200px",
                }}
              >
                <img
                  src={feature.icon}
                  alt={feature.name}
                  className="w-24 h-24"
                />
              </div>
              <div className="text-base font-mazzardbold text-left w-full mb-2">
                {feature.name}
              </div>
              <div className="text-xs text-left w-full">
                {feature.description}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Features;
