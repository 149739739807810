import { useState, useEffect } from "react";
import { FilterBar } from "../../components";
import CarDetailsCard from "../../components/CarDetailsCard/CarDetailsCard";
import Sidebar from "../../components/Sidebar/Sidebar";
import { useNavigate, useLocation } from "react-router-dom";

// Import car images
import car1 from "../../assets/images/car_img.png";
// Import other car images as needed or use the same for all

// Demo car data
const demoCarData = [
  {
    id: "car1",
    name: "Aston Martin",
    image: car1,
    rating: 4.7,
    reviewCount: 2423,
    category: "Hatchback",
    cabType: "hatchback",
    fuelTypeCategory: "petrol",
    model: "swift",
    features: ["Hatchback", "AC", "4 Seats", "149 kms included"],
    compactDescription: "Compact Car",
    extraKmFare: "₹ 15/km after 149 km",
    fuelType: "Petrol",
    cancellation: "1 hour",
    isAvailable: true,
  },
  {
    id: "car2",
    name: "Maruti Suzuki Swift",
    image: car1,
    rating: 4.5,
    reviewCount: 1835,
    category: "Hatchback",
    cabType: "hatchback",
    fuelTypeCategory: "cng",
    model: "swift",
    features: ["Hatchback", "AC", "4 Seats", "120 kms included"],
    compactDescription: "Compact Car",
    extraKmFare: "₹ 12/km after 120 km",
    fuelType: "CNG with refill breaks",
    cancellation: "2 hours",
    isAvailable: true,
  },
  {
    id: "car3",
    name: "Toyota Innova",
    image: car1,
    rating: 4.8,
    reviewCount: 3250,
    category: "SUV",
    cabType: "suv",
    fuelTypeCategory: "diesel",
    model: "innova",
    features: ["SUV", "AC", "7 Seats", "200 kms included"],
    compactDescription: "Luxury SUV",
    extraKmFare: "₹ 18/km after 200 km",
    fuelType: "Diesel",
    cancellation: "24 hours",
    isAvailable: true,
  },
  {
    id: "car4",
    name: "Honda City",
    image: car1,
    rating: 4.6,
    reviewCount: 2050,
    category: "Sedan",
    cabType: "sedan",
    fuelTypeCategory: "petrol",
    model: "ertiga",
    features: ["Sedan", "AC", "5 Seats", "180 kms included"],
    compactDescription: "Premium Sedan",
    extraKmFare: "₹ 14/km after 180 km",
    fuelType: "Petrol",
    cancellation: "6 hours",
    isAvailable: false,
  },
  {
    id: "car5",
    name: "Maruti Suzuki Ertiga",
    image: car1,
    rating: 4.4,
    reviewCount: 1875,
    category: "MPV",
    cabType: "suv",
    fuelTypeCategory: "cng",
    model: "ertiga",
    features: ["MPV", "AC", "7 Seats", "150 kms included"],
    compactDescription: "Compact MPV",
    extraKmFare: "₹ 13/km after 150 km",
    fuelType: "CNG with refill breaks",
    cancellation: "3 hours",
    isAvailable: true,
  },
  {
    id: "car6",
    name: "Wagon R",
    image: car1,
    rating: 4.2,
    reviewCount: 1650,
    category: "Hatchback",
    cabType: "hatchback",
    fuelTypeCategory: "cng",
    model: "wagonr",
    features: ["Hatchback", "AC", "4 Seats", "130 kms included"],
    compactDescription: "Budget Friendly",
    extraKmFare: "₹ 11/km after 130 km",
    fuelType: "CNG with refill breaks",
    cancellation: "2 hours",
    isAvailable: true,
  },
];

interface Filters {
  cabType: string[];
  fuelType: string[];
  cabModel: string[];
}

// Utility functions for filter state management
const saveFiltersToLocalStorage = (filters: Filters) => {
  localStorage.setItem("cabFilters", JSON.stringify(filters));
};

const getFiltersFromLocalStorage = (): Filters | null => {
  const savedFilters = localStorage.getItem("cabFilters");
  return savedFilters ? JSON.parse(savedFilters) : null;
};

const getFiltersFromUrl = (): Filters => {
  const params = new URLSearchParams(window.location.search);
  const filters: Filters = {
    cabType: [],
    fuelType: [],
    cabModel: [],
  };

  // Parse cabType parameters
  const cabType = params.get("cabType");
  if (cabType) filters.cabType = cabType.split(",");

  // Parse fuelType parameters
  const fuelType = params.get("fuelType");
  if (fuelType) filters.fuelType = fuelType.split(",");

  // Parse cabModel parameters
  const cabModel = params.get("cabModel");
  if (cabModel) filters.cabModel = cabModel.split(",");

  return filters;
};

const updateUrlWithFilters = (filters: Filters, navigate: any) => {
  const params = new URLSearchParams();

  if (filters.cabType.length > 0) {
    params.set("cabType", filters.cabType.join(","));
  }

  if (filters.fuelType.length > 0) {
    params.set("fuelType", filters.fuelType.join(","));
  }

  if (filters.cabModel.length > 0) {
    params.set("cabModel", filters.cabModel.join(","));
  }

  const queryString = params.toString();
  const newUrl = queryString ? `?${queryString}` : "";

  navigate(newUrl, { replace: true });
};

const Dashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [cars, setCars] = useState(demoCarData);

  // Initialize filters from URL or localStorage
  const [filters, setFilters] = useState<Filters>(() => {
    // First try to get filters from URL
    const urlFilters = getFiltersFromUrl();
    if (
      urlFilters.cabType.length > 0 ||
      urlFilters.fuelType.length > 0 ||
      urlFilters.cabModel.length > 0
    ) {
      return urlFilters;
    }

    // Then try localStorage
    const savedFilters = getFiltersFromLocalStorage();
    if (savedFilters) {
      return savedFilters;
    }

    // Default empty filters
    return {
      cabType: [],
      fuelType: [],
      cabModel: [],
    };
  });

  // Handle car selection
  const handleSelectCar = (id: string) => {
    console.log(`Selected car with ID: ${id}`);
    // Add booking logic here
  };

  // Handle filter changes
  const handleFilterChange = (
    filterType: string,
    value: string,
    isChecked: boolean
  ) => {
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };

      // Add type assertion here
      const key = filterType as keyof Filters;

      if (isChecked) {
        updatedFilters[key] = [...updatedFilters[key], value];
      } else {
        updatedFilters[key] = updatedFilters[key].filter(
          (item) => item !== value
        );
      }

      return updatedFilters;
    });
  };

  // Save filters to localStorage and update URL whenever filters change
  useEffect(() => {
    saveFiltersToLocalStorage(filters);
    updateUrlWithFilters(filters, navigate);
  }, [filters, navigate]);

  // Apply filters to car list
  useEffect(() => {
    // Monitor URL changes to update filters
    const urlFilters = getFiltersFromUrl();
    const filtersChanged =
      JSON.stringify(urlFilters.cabType) !== JSON.stringify(filters.cabType) ||
      JSON.stringify(urlFilters.fuelType) !==
        JSON.stringify(filters.fuelType) ||
      JSON.stringify(urlFilters.cabModel) !== JSON.stringify(filters.cabModel);

    if (filtersChanged) {
      setFilters(urlFilters);
    }
  }, [location]);

  // Filter cars based on selected filters
  useEffect(() => {
    let filteredCars = demoCarData;

    // Apply cab type filter
    if (filters.cabType.length > 0) {
      filteredCars = filteredCars.filter((car) =>
        filters.cabType.includes(car.cabType)
      );
    }

    // Apply fuel type filter
    if (filters.fuelType.length > 0) {
      filteredCars = filteredCars.filter((car) =>
        filters.fuelType.includes(car.fuelTypeCategory)
      );
    }

    // Apply cab model filter
    if (filters.cabModel.length > 0) {
      filteredCars = filteredCars.filter((car) =>
        filters.cabModel.includes(car.model)
      );
    }

    setCars(filteredCars);
  }, [filters]);

  // Set initial checkbox states in Sidebar based on filters
  useEffect(() => {
    // This is handled by passing filters to Sidebar and updating checkbox state there
  }, []);

  return (
    <div className="flex min-h-screen">
      {/* Fixed Sidebar */}
      <div className="fixed top-0 left-0 h-full w-1/6 border-r border-gray-200 z-10">
        <Sidebar onFilterChange={handleFilterChange} initialFilters={filters} />
      </div>

      {/* Main Content with left margin to accommodate fixed sidebar */}
      <div className="w-full ml-[16.666667%] flex flex-col h-screen">
        {/* Fixed Filters */}
        <div className="fixed top-0 right-0 w-[83.333333%] z-10 bg-black">
          <FilterBar />
        </div>

        {/* Scrollable Car Cards Container - with top padding to push content below fixed header */}
        <div className="mt-[88px] p-6 bg-[#D7D7D7] overflow-y-auto flex-1">
          <div className="grid gap-6">
            {cars.length > 0 ? (
              cars.map((car) => (
                <CarDetailsCard
                  key={car.id}
                  id={car.id}
                  name={car.name}
                  image={car.image}
                  rating={car.rating}
                  reviewCount={car.reviewCount}
                  category={car.category}
                  features={car.features}
                  compactDescription={car.compactDescription}
                  extraKmFare={car.extraKmFare}
                  fuelType={car.fuelType}
                  cancellation={car.cancellation}
                  isAvailable={car.isAvailable}
                  onSelect={handleSelectCar}
                />
              ))
            ) : (
              <div className="text-center py-8 text-gray-500">
                No cars match your current filter criteria. Try adjusting your
                filters.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
