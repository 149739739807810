import { PhoneIcon, EnvelopeIcon } from "@heroicons/react/24/solid";
import PrimaryLogo from "../../assets/images/primarylogo.png";
import { FaFacebook, FaInstagram, FaTwitter, FaLinkedin } from "react-icons/fa";

import "./Footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="w-full flex justify-center items-center my-4">
      <div className="w-full flex flex-col gap-4 lg:flex-row lg:justify-between lg:items-start p-4">
        {/* Logo Section */}
        <div className="w-full lg:w-1/4 flex justify-center lg:justify-start mb-4 lg:mb-0">
          <img
            src={PrimaryLogo}
            alt="Primary Logo"
            className="h-12"
            style={{
              height: "55px",
              width: "auto",
            }}
          />
        </div>

        {/* Links Section */}
        <div className="gap-3 sm:gap-12 text-center lg:text-left w-full lg:w-3/4 flex justify-between flex-col sm:flex-row  sm:justify-center">
          <div className="w-full sm:w-1/3  lg:mb-0">
            <div className="font-mazzardbold text-xl mb-2">Informations</div>
            <ul>
              <li className="text-md mb-1">About us</li>
              <li className="text-md mb-1">More Search</li>
              <li className="text-md mb-1">Testimonials</li>
            </ul>
          </div>
          <div className="w-full sm:w-1/3   lg:mb-0">
            <div className="font-mazzardbold text-xl mb-2">Helpful Links</div>
            <ul>
              <li className="text-md mb-1">Services</li>
              <li className="text-md mb-1">Supports</li>
              <li className="text-md mb-1">
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li className="text-md mb-1">
                <Link to="/terms-and-conditions">Terms & Conditions</Link>
              </li>
            </ul>
          </div>
          <div className=" w-full sm:w-1/3  lg:mb-0 ">
            <div className="font-mazzardbold text-xl mb-2">Platforms</div>
            <ul className="">
              <li className="text-md mb-1">Admin</li>
              <li className="text-md mb-1">Vendor</li>
              <li className="text-md mb-1">Driver</li>
            </ul>
          </div>
        </div>

        {/* Contact Section */}
        <div className="w-full lg:w-1/4 flex flex-col items-center lg:items-start">
          <h2 className="text-lg font-bold mb-2">Contact Us</h2>
          <p className="flex items-center mb-2">
            <PhoneIcon className="h-5 w-5 mr-2" />
            <a href="tel:+918309412828" className="text-blue-600">
              +91 8309412828
            </a>
          </p>
          <p className="flex items-center mb-2">
            <EnvelopeIcon className="h-5 w-5 mr-2" />
            <a href="mailto:info@fusioncab.com" className="text-blue-600">
              ride@fusioncab.com
            </a>
          </p>
          <div className="w-full lg:w-1/4 flex flex-col items-center lg:items-start">
            <div className="icons flex gap-4 mt-2">
              <p className="flex items-center mb-2">
                <a href="https://x.com/FusionCab">
                  <FaTwitter className="h-7 w-7" />
                </a>
              </p>
              <p className="flex items-center mb-2">
                <a href="https://www.instagram.com/fusioncab">
                  <FaInstagram className="h-7 w-7" />
                </a>
              </p>
              <p className="flex items-center mb-2">
                <a href="https://www.facebook.com/info.fusioncab">
                  <FaFacebook className="h-7 w-7" />
                </a>
              </p>
              <p className="flex items-center mb-2">
                <a href="https://www.linkedin.com/company/fusion-cab/">
                  <FaLinkedin className="h-7 w-7" />
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
