import PersonalizedImage from '../assets/images/features/personalised.png'
import ScalabilityImage from '../assets/images/features/scalability.png'
import OnboardingImage from '../assets/images/features/onboarding.png'
import ManagementImage from '../assets/images/features/management.png'
import ResultOrientedImage from '../assets/images/features/resultoriented.png'
import SupportImage from '../assets/images/features/support.png'
import InventoryImage from '../assets/images/features/inventory.png'
import FlexibilityImage from '../assets/images/features/flexibility.png'

const features = [
    {
        'name': 'Personalized Method',
        'description': 'Excellent flexibility to incorporate numerous business models & ways to Rent a Cab',
        'icon': PersonalizedImage,
        'color': 'blue'
    },
    {
        'name': 'Global Scalability',
        'description': 'Ability to support different forms of transportation & multilingual approach',
        'icon': ScalabilityImage,
        'color': 'black'
    },
    {
        'name': 'Swift On-boarding',
        'description': 'Quick rollout and adaptable integration',
        'icon': OnboardingImage,
        'color': 'blue'
    },
    {
        'name': 'Result oriented payments',
        'description': 'Transparent & realistic payment methods, such as subscription, per-transaction-based payment & many others',
        'icon': ResultOrientedImage,
        'color': 'black'
    },
    {
        'name': 'Hassle-free Business Management',
        'description': 'Book, manage, explore and report all from one application',
        'icon': ManagementImage,
        'color': 'black'
    },
    {
        'name': 'Large inventory',
        'description': 'Get the greatest deals & advantages of discounted prices nationwide',
        'icon': InventoryImage,
        'color': 'blue'
    },
    {
        'name': 'Flexibility and safety',
        'description': 'Updates on travel advisories & safety measures to ensure a safe travel experience. Cancel or modify what you require, and receive a refund',
        'icon': FlexibilityImage,
        'color': 'black'
    },
    {
        'name': 'Support',
        'description': '24-hour, top-notch customer service with a goal response time of 15 seconds, handled by real humans rather than automated systems!',
        'icon': SupportImage,
        'color': 'blue'
    },
]

export default features