import logo from "../../assets/images/primarylogo.png";

interface FilterOption {
  id: string;
  label: string;
}

interface FilterGroupProps<T extends string> {
  title: string;
  options: FilterOption[];
  filterType: T;
  onChange: (filterType: T, value: string, isChecked: boolean) => void;
  activeFilters: string[];
}

interface SidebarProps {
  onFilterChange: <T extends string>(
    filterType: T,
    value: string,
    isChecked: boolean
  ) => void;
  initialFilters: {
    cabType: string[];
    fuelType: string[];
    cabModel: string[];
  };
}

const FilterGroup = <T extends string>({
  title,
  options,
  filterType,
  onChange,
  activeFilters,
}: FilterGroupProps<T>) => {
  return (
    <div className="mb-6">
      <h2 className="text-lg font-semibold mb-2">{title}</h2>
      <ul>
        {options.map((option) => (
          <li key={option.id} className="mb-1.5">
            <input
              id={option.id}
              type="checkbox"
              className="mr-2"
              checked={activeFilters.includes(option.id)}
              onChange={(e) =>
                onChange(filterType, option.id, e.target.checked)
              }
            />
            <label htmlFor={option.id}>{option.label}</label>
          </li>
        ))}
      </ul>
    </div>
  );
};

const Sidebar = ({ onFilterChange, initialFilters }: SidebarProps) => {
  const cabTypes: FilterOption[] = [
    { id: "hatchback", label: "Hatchback" },
    { id: "sedan", label: "Sedan" },
    { id: "suv", label: "SUV" },
  ];

  const fuelTypes: FilterOption[] = [
    { id: "cng", label: "CNG" },
    { id: "petrol", label: "Petrol" },
    { id: "diesel", label: "Diesel" },
  ];

  const cabModels: FilterOption[] = [
    { id: "wagonr", label: "Wagon R" },
    { id: "swift", label: "Swift Dezire" },
    { id: "ertiga", label: "Maruti Suzuki Ertiga" },
    { id: "innova", label: "Toyota Innova" },
  ];

  return (
    <div className="bg-white p-6 h-screen overflow-y-auto w-full">
      <div className="flex items-center mb-8">
        <img alt="Fusion Cab Logo" className="mr-2" height="10" src={logo} />
      </div>

      <FilterGroup
        title="Cab Type"
        options={cabTypes}
        filterType="cabType"
        onChange={onFilterChange}
        activeFilters={initialFilters.cabType}
      />
      <FilterGroup
        title="Fuel Type"
        options={fuelTypes}
        filterType="fuelType"
        onChange={onFilterChange}
        activeFilters={initialFilters.fuelType}
      />
      <FilterGroup
        title="Cab Model"
        options={cabModels}
        filterType="cabModel"
        onChange={onFilterChange}
        activeFilters={initialFilters.cabModel}
      />
    </div>
  );
};

export default Sidebar;
