import React, { useEffect } from "react";
import { Navbar } from "../../components";
import Footer from "../../components/Footer/Footer";
import AOS from "aos";

const Terms = () => {
  useEffect(() => {
    AOS.init({
      duration: 600,
    });
    // Scroll to top when component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="navbar shadow-md sticky top-0 overflow-hidden z-20">
        <div style={{ maxWidth: "1350px", margin: "0 auto" }}>
          <Navbar />
        </div>
      </div>
      <div
        className="px-3 sm:px-10"
        style={{ maxWidth: "1200px", margin: "0 auto" }}
      >
        <div className="my-8 max-w-4xl mx-auto">
          <h1 className="text-4xl font-mazzardbold mb-6" data-aos="zoom-in">
            Terms & Conditions
          </h1>
          <div className="space-y-6 text-gray-700">
            <section data-aos="fade-right" data-aos-delay="100">
              <h2 className="text-2xl font-mazzardbold">1. Introduction</h2>
              <p className="mt-4">
                Welcome to Fusion Cab! By accessing or using our website and
                mobile application ("Platform"), you agree to comply with and be
                bound by these Terms & Conditions ("Terms"). If you do not agree
                with these Terms, please do not use our services.
              </p>
            </section>

            <section className="mt-8" data-aos="fade-left" data-aos-delay="200">
              <h2 className="text-2xl font-mazzardbold">2. Services Offered</h2>
              <p className="mt-4">
                Fusion Cab provides chauffeur-driven cab rental services,
                including intercity travel, airport transfers, and hourly
                rentals. The use of our services is subject to availability and
                may change without prior notice.
              </p>
            </section>

            <section
              className="mt-8"
              data-aos="fade-right"
              data-aos-delay="300"
            >
              <h2 className="text-2xl font-mazzardbold">3. User Eligibility</h2>
              <p className="mt-4">
                To use our services, you must:
                <ul className="list-disc pl-6">
                  <li>
                    Possess the legal authority to create a binding agreement.
                  </li>
                  <li>
                    Ensure that all information provided during registration is
                    accurate and up-to-date.
                  </li>
                </ul>
              </p>
            </section>

            <section className="mt-8" data-aos="fade-left" data-aos-delay="400">
              <h2 className="text-2xl font-mazzardbold">
                4. Account Registration
              </h2>
              <p className="mt-4">
                Users are required to register an account to access certain
                features of our Platform. You are responsible for maintaining
                the confidentiality of your account information, including your
                password, and for all activities that occur under your account.
              </p>
            </section>

            <section className="mt-8">
              <h2 className="text-2xl font-mazzardbold">
                5. Booking and Cancellation Policy
              </h2>
              <p className="mt-4">
                <strong>Booking:</strong> All bookings are subject to
                availability. Confirmation of your booking will be provided via
                email or SMS.
              </p>
              <p className="mt-4">
                <strong>Cancellation:</strong> Cancellations must be made
                through the Platform. Cancellation fees may apply as per our
                cancellation policy, which is subject to change and will be
                clearly stated during the booking process.
              </p>
            </section>
            <section className="mt-8">
              <h2 className="text-2xl font-mazzardbold">6. Payment Terms</h2>
              <p className="mt-4">
                <strong>Payment Methods:</strong> We accept various payment
                methods, including credit/debit cards and digital wallets.
                Payment is required at the time of booking or as otherwise
                stated.
              </p>
              <p className="mt-4">
                <strong>Refunds:</strong> Refunds for cancellations will be
                processed according to our cancellation policy. Refunds, if
                applicable, will be made using the original payment method.
              </p>
            </section>

            <section className="mt-8">
              <h2 className="text-2xl font-mazzardbold">
                7. User Responsibilities
              </h2>
              <p className="mt-4">
                By using our services, you agree to:
                <ul className="list-disc pl-6">
                  <li>Provide accurate and complete information.</li>
                  <li>Comply with all applicable laws and regulations.</li>
                  <li>
                    Use the Platform and our services for lawful purposes only.
                  </li>
                  <li>
                    Not engage in any behavior that may harm or disrupt our
                    services or the experience of other users.
                  </li>
                </ul>
              </p>
            </section>

            <section className="mt-8">
              <h2 className="text-2xl font-mazzardbold">
                8. Limitation of Liability
              </h2>
              <p className="mt-4">
                Fusion Cab will not be liable for any direct, indirect,
                incidental, or consequential damages arising out of or in
                connection with your use of our Platform or services. This
                includes, but is not limited to, damages for loss of profits,
                goodwill, use, data, or other intangible losses.
              </p>
            </section>

            <section className="mt-8">
              <h2 className="text-2xl font-mazzardbold">9. Privacy Policy</h2>
              <p className="mt-4">
                Your privacy is important to us. Please review our Privacy
                Policy to understand how we collect, use, and protect your
                personal information.
              </p>
            </section>

            <section className="mt-8">
              <h2 className="text-2xl font-mazzardbold">
                10. Intellectual Property
              </h2>
              <p className="mt-4">
                All content on our Platform, including text, graphics, logos,
                icons, images, and software, is the property of Fusion Cab or
                its licensors and is protected by copyright and other
                intellectual property laws. You may not use, reproduce,
                distribute, or create derivative works from any content on the
                Platform without our express written permission.
              </p>
            </section>

            <section className="mt-8">
              <h2 className="text-2xl font-mazzardbold">11. Termination</h2>
              <p className="mt-4">
                We reserve the right to terminate or suspend your access to our
                Platform and services at our sole discretion, without prior
                notice, for conduct that we believe violates these Terms or is
                harmful to our interests, another user, or a third party.
              </p>
            </section>

            <section className="mt-8">
              <h2 className="text-2xl font-mazzardbold">
                12. Modifications to Terms
              </h2>
              <p className="mt-4">
                Fusion Cab reserves the right to modify these Terms at any time.
                Any changes will be effective immediately upon posting on our
                Platform. Your continued use of our services after any
                modifications signifies your acceptance of the revised Terms.
              </p>
            </section>

            <section className="mt-8">
              <h2 className="text-2xl font-mazzardbold">
                13. Governing Law and Jurisdiction
              </h2>
              <p className="mt-4">
                These Terms are governed by the laws of India, without regard to
                its conflict of law principles. Any disputes arising from these
                Terms or your use of our services will be subject to the
                exclusive jurisdiction of the courts located in Hyderabad,
                India.
              </p>
            </section>
            <section className="mt-8" data-aos="fade-up" data-aos-delay="1000">
              <h2 className="text-2xl font-mazzardbold">14. Contact Information</h2>
              <p className="mt-4">
                If you have any questions or concerns about this Privacy Policy
                or our data practices, please contact us at:
              </p>
              <div className="mt-4">
                <p className="font-semibold">Fusion Cab</p>
                <p>SNR Fleet Services Private Limited</p>
                <p>29, Sai Sannadhi, Pragathi Enclave, Miyapur,</p>
                <p>Hyderabad, TS – 49</p>
                <p>
                  <a href="mailto:info@fusioncab.com" className="text-blue-600">
                    info@fusioncab.com
                  </a>
                </p>
                <p>
                  <a href="tel:+918309412828" className="text-blue-600">
                    +91 8309412828
                  </a>
                </p>
              </div>
            </section>
            <section className="mt-8" data-aos="fade-up" data-aos-delay="600">
              <h2 className="text-2xl font-mazzardbold">15. Disclaimer</h2>
              <p className="mt-4">
                Fusion Cab provides services on an "as is" and "as available"
                basis. We do not guarantee that the Platform or services will be
                uninterrupted, error-free, or free from viruses or other harmful
                components.
              </p>
            </section>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Terms;
