import React from "react";
import { useSpring, animated } from "@react-spring/web";

const ComingSoon = () => {
  const props = useSpring({
    from: { opacity: 0, transform: "translateY(-20px)" },
    to: { opacity: 1, transform: "translateY(0px)" },
    config: { duration: 1200 },
  });

  return (
    <div className="flex w-full py-2 my-2 box-border justify-center items-center h-28">
      <animated.div style={props} className="text-4xl font-mazzardbold">
        Coming Soon!
      </animated.div>
    </div>
  );
};

export default ComingSoon;
