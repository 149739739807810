import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import logo from "../../../assets/images/primarylogo.png";
import worldMap from "../../../assets/images/world_map.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLock,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import "./Login.css";

const Login = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Handle login logic here
  };

  return (
    <div
      className="min-h-screen flex flex-col items-center justify-center bg-gray-100 relative"
      style={{
        backgroundImage: `url(${worldMap})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <header className="w-full flex justify-between items-center p-6 bg-gray-100">
        <div className="flex items-center">
          <img src={logo} alt="Fusion Cab Logo" className="h-10 " />
        </div>
        <nav className="hidden md:flex space-x-8">
          <Link to={"/"} className="text-gray-600 link hover:text-black">
            Home
          </Link>
          <Link to={"/about"} className="text-gray-600 link hover:text-black">
            About
          </Link>
          <Link
            to={"/services"}
            className="text-gray-600 link hover:text-black"
          >
            Services
          </Link>
          <Link
            to={"/platform"}
            className="text-gray-600 link hover:text-black"
          >
            Platform
          </Link>
          <Link to={"/contact"} className="text-gray-600 link hover:text-black">
            Contact
          </Link>
        </nav>
        <Link
          to={"/signup"}
          className="text-white flex items-center bg-black px-4 py-2 rounded-lg"
        >
          Sign up <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
        </Link>
      </header>
      <main
        className="flex-grow flex items-center justify-center"
        style={{ minWidth: "350px" }}
      >
        <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
          <h2 className="text-2xl font-semibold mb-6">Log In</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-gray-700">Email address*</label>
              <div className="flex items-center mt-2 p-2 border-l-2 border-gray-300 focus-within:border-black">
                <FontAwesomeIcon
                  icon={faEnvelope}
                  className="text-gray-400 mr-2"
                />
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Enter your email"
                  className="w-full focus:outline-none"
                />
              </div>
            </div>
            <div className="mb-6">
              <label className="block text-gray-700">Password*</label>
              <div className="flex items-center mt-2 p-2 border-l-2 border-gray-300 focus-within:border-black">
                <FontAwesomeIcon icon={faLock} className="text-gray-400 mr-2" />
                <input
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  placeholder="Enter your password"
                  className="w-full focus:outline-none"
                />
              </div>
            </div>
            <button
              type="submit"
              className="w-full bg-black text-white py-2 rounded-lg hover:bg-gray-800"
            >
              Log in <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
            </button>
          </form>
          <p className="mt-4 text-center text-sm text-gray-600">
            Don't have an account?{" "}
            <Link to={"/signup"} className="text-black font-semibold">
              Sign up
            </Link>
          </p>
        </div>
      </main>
    </div>
  );
};

export default Login;
