import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faSearch,
  faTimes,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import "./Filterbar.css";

const FilterBar = () => {
  const [tripType, setTripType] = useState("Airport Cab");
  const [fromLocation, setFromLocation] = useState("Pickup Location");
  const [dropOffLocation, setDropOffLocation] = useState("Select Location");
  const [pickupDate, setPickupDate] = useState("");
  const [pickupTime, setPickupTime] = useState("");
  const [tempTime, setTempTime] = useState(""); // Temporary time before applying
  const [searchVisible, setSearchVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [timeDropdownOpen, setTimeDropdownOpen] = useState(false);

  // Track which dropdown is open
  const [openDropdownId, setOpenDropdownId] = useState<string | null>(null);

  // Refs for dropdown containers to detect outside clicks
  const dropdownRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const searchInputRef = useRef<HTMLInputElement>(null);
  const timeInputRef = useRef<HTMLInputElement>(null);

  const filters = [
    {
      id: "tripType",
      label: "Trip Type",
      value: tripType,
      setValue: setTripType,
      type: "select",
      options: ["Airport Cab", "City Cab", "Outstation Cab"],
    },
    {
      id: "fromLocation",
      label: "From",
      value: fromLocation,
      setValue: setFromLocation,
      type: "select",
      options: ["Pickup Location", "Location 1", "Location 2"],
    },
    {
      id: "dropOffLocation",
      label: "Drop Off",
      value: dropOffLocation,
      setValue: setDropOffLocation,
      type: "select",
      options: ["Select Location", "Location 1", "Location 2"],
    },
    {
      id: "pickupDate",
      label: "Pick-up Date",
      value: pickupDate,
      setValue: setPickupDate,
      type: "date",
    },
    {
      id: "pickupTime",
      label: "Pick-up Time",
      value: pickupTime,
      setValue: setPickupTime,
      type: "time", // Keep using native time input
    },
  ];

  // Toggle dropdown
  const toggleDropdown = (id: string) => {
    if (id === "pickupTime") {
      // For time dropdown, we'll handle it separately
      setTimeDropdownOpen(!timeDropdownOpen);
      setTempTime(pickupTime); // Initialize temp time with current value
      return;
    }
    setOpenDropdownId(openDropdownId === id ? null : id);
  };

  // Handle time change
  const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTempTime(e.target.value);
  };

  // Apply time selection
  const applyTimeSelection = () => {
    setPickupTime(tempTime);
    setTimeDropdownOpen(false);
  };

  // Cancel time selection
  const cancelTimeSelection = () => {
    setTempTime(pickupTime); // Reset to current value
    setTimeDropdownOpen(false);
  };

  // Handle selection of an option (for non-time dropdowns)
  const handleOptionSelect = (id: string, option: string) => {
    const filter = filters.find((f) => f.id === id);
    if (filter) {
      filter.setValue(option);
      setOpenDropdownId(null);
    }
  };

  // Toggle search bar
  const toggleSearchBar = () => {
    const newState = !searchVisible;
    setSearchVisible(newState);
    if (newState) {
      // Focus search input when visible
      setTimeout(() => {
        searchInputRef.current?.focus();
      }, 100);
    } else {
      // Clear search when closing
      setSearchQuery("");
    }
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // Handle regular dropdowns
      if (
        openDropdownId &&
        dropdownRefs.current[openDropdownId] &&
        !dropdownRefs.current[openDropdownId]?.contains(event.target as Node)
      ) {
        setOpenDropdownId(null);
      }

      // Handle time dropdown separately
      if (
        timeDropdownOpen &&
        timeInputRef.current &&
        !timeInputRef.current.contains(event.target as Node)
      ) {
        cancelTimeSelection();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openDropdownId, timeDropdownOpen]);

  // Custom dropdown component with increased width
  const CustomDropdown = ({
    id,
    value,
    options,
  }: {
    id: string;
    value: string;
    options: string[];
  }) => {
    return (
      <div
        className="relative w-full"
        ref={(el) => (dropdownRefs.current[id] = el)}
      >
        {/* Dropdown trigger with fixed width and ellipsis for overflow */}
        <div
          className="bg-black text-white text-lg py-1 rounded cursor-pointer pr-8 flex items-center w-full overflow-hidden whitespace-nowrap text-ellipsis"
          onClick={() => toggleDropdown(id)}
        >
          <span className="truncate">{value}</span>
          <FontAwesomeIcon
            icon={faChevronDown}
            className={`absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400 transition-transform duration-200 ${
              openDropdownId === id ? "rotate-180" : ""
            }`}
          />
        </div>

        {/* Dropdown options with fixed width */}
        {openDropdownId === id && (
          <div className="absolute top-full left-0 w-full mt-1 bg-gray-800 rounded shadow-lg z-10 max-h-60 overflow-y-auto">
            {options.map((option) => (
              <div
                key={option}
                className="px-4 py-2 hover:bg-gray-700 cursor-pointer text-white truncate"
                onClick={() => handleOptionSelect(id, option)}
              >
                {option}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  // Time Input component with apply button
  const TimeInputWithApply = () => {
    return (
      <div className="relative w-full" ref={timeInputRef}>
        {/* Time input trigger */}
        <div className="bg-black text-white text-lg py-1 rounded cursor-pointer pr-8 flex items-center w-full relative">
          <input
            type="time"
            className="opacity-0 absolute inset-0 w-full h-full cursor-pointer"
            value={tempTime}
            onChange={handleTimeChange}
            onFocus={() => {
              setTimeDropdownOpen(true);
              setTempTime(pickupTime);
            }}
          />
          <span className="truncate z-10">
            {pickupTime ? formatTimeDisplay(pickupTime) : "Select Time"}
          </span>
          <FontAwesomeIcon
            icon={faClock}
            className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400 z-10"
            onClick={(e) => {
              e.preventDefault();
              setTimeDropdownOpen(true);
              setTempTime(pickupTime);
              (
                timeInputRef.current?.querySelector(
                  'input[type="time"]'
                ) as HTMLInputElement | null
              )?.focus();
            }}
          />
        </div>

        {/* Time input dropdown */}
        {timeDropdownOpen && (
          <div className="absolute top-full left-0 w-full mt-1 bg-gray-800 rounded shadow-lg z-10 p-3">
            <input
              type="time"
              className="w-full mb-3 p-2 bg-gray-700 text-white border-0 rounded"
              value={tempTime}
              onChange={handleTimeChange}
              autoFocus
            />
            <div className="flex justify-between">
              <button
                className="px-4 py-1 bg-gray-700 hover:bg-gray-600 text-white rounded transition text-sm"
                onClick={cancelTimeSelection}
              >
                Cancel
              </button>
              <button
                className="px-4 py-1 bg-[#465EA9] hover:bg-[#3D5299] text-white rounded transition text-sm"
                onClick={applyTimeSelection}
                disabled={!tempTime}
              >
                Apply
              </button>
            </div>
          </div>
        )}
      </div>
    );
  };

  // Format time for display (convert from 24h to 12h format)
  const formatTimeDisplay = (time: string): string => {
    if (!time) return "Select Time";

    try {
      const [hours, minutes] = time.split(":");
      const hour = parseInt(hours, 10);
      const ampm = hour >= 12 ? "PM" : "AM";
      const hour12 = hour % 12 || 12;
      return `${hour12}:${minutes} ${ampm}`;
    } catch (e) {
      return time;
    }
  };

  return (
    <div className="flex items-center justify-center w-full">
      <div
        className={`flex items-center p-4 w-full ${
          searchVisible ? "hidden" : "flex"
        }`}
      >
        <div className="flex-grow flex items-center">
          {filters.map(({ id, label, value, setValue, type, options = [] }) => (
            <div className="flex flex-col w-52 border-r-2 mr-4" key={id}>
              <label className="text-sm mb-1" style={{ color: "#90A3BF" }}>
                {label}
              </label>
              {id === "pickupTime" ? (
                <TimeInputWithApply />
              ) : type === "select" ? (
                <CustomDropdown id={id} value={value} options={options} />
              ) : (
                <input
                  type={type}
                  className="bg-black text-white text-lg appearance-none pr-8 py-1 rounded focus:outline-none border-none w-full"
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                />
              )}
            </div>
          ))}
        </div>

        {/* Search button pushed to the right */}
        <div
          className="flex items-center justify-center w-12 h-12 bg-white rounded-full cursor-pointer hover:bg-gray-200 transition-all flex-shrink-0 ml-auto"
          onClick={toggleSearchBar}
        >
          <FontAwesomeIcon icon={faSearch} className="text-black" />
        </div>
      </div>

      {/* Full-width search bar */}
      <div
        className={`w-full bg-black p-4 transition-all duration-300 ease-in-out ${
          searchVisible ? "opacity-100" : "opacity-0 hidden"
        }`}
      >
        <div className="flex items-center bg-gray-800 rounded px-4 py-2">
          <FontAwesomeIcon icon={faSearch} className="text-gray-400 mr-3" />
          <input
            ref={searchInputRef}
            type="text"
            placeholder="Search for locations, cab types, etc."
            className="bg-transparent text-white text-lg appearance-none w-full focus:outline-none border-none"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          {searchQuery && (
            <FontAwesomeIcon
              icon={faTimes}
              className="text-gray-400 cursor-pointer hover:text-white"
              onClick={() => setSearchQuery("")}
            />
          )}
          <button
            className="ml-3 px-4 py-1 bg-[#465EA9] hover:bg-[#3D5299] text-white rounded transition"
            onClick={() => {
              /* Implement search functionality */
            }}
          >
            Search
          </button>
          <button
            className="ml-3 px-4 py-1 bg-gray-700 hover:bg-gray-600 text-white rounded transition"
            onClick={toggleSearchBar}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterBar;
