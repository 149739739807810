import React from "react";
import { Navigation, Droplet, Clock } from "lucide-react";

interface CarDetailsProps {
  id: string;
  name: string;
  image: string;
  rating: number;
  reviewCount: number;
  category: string;
  features: string[];
  compactDescription: string;
  extraKmFare: string;
  fuelType: string;
  cancellation: string;
  isAvailable: boolean;
  onSelect: (id: string) => void;
}

const CarDetailsCard: React.FC<CarDetailsProps> = ({
  id,
  name,
  image,
  rating,
  reviewCount,
  category,
  features,
  compactDescription,
  extraKmFare,
  fuelType,
  cancellation,
  isAvailable,
  onSelect,
}) => {
  return (
    <div className="flex items-center justify-between p-6 border border-gray-200 bg-white rounded-lg shadow-sm w-full relative">
      {/* Left side - Car Image */}
      <div className="w-1/3">
        <img
          src={image}
          alt={`${name} car`}
          className="object-contain w-full h-auto"
        />
      </div>

      {/* Right side - Car details */}
      <div className="w-2/3 pl-6">
        {/* Car name and rating */}
        <div className="flex justify-between items-center mb-1">
          <div className="flex items-center">
            <h2 className="text-xl font-bold">{name}</h2>
            <span className="text-gray-500 ml-2">or similar</span>
          </div>
          <div className="flex items-center">
            <div className="flex items-center bg-[#465EA9] text-white px-2 py-1 rounded-md">
              <span className="font-bold">{rating}</span>
              <span className="text-xs">/5</span>
            </div>
            <span className="text-gray-500 text-sm ml-2">
              {reviewCount} ratings
            </span>
          </div>
        </div>

        {/* Car features */}
        <div className="flex items-center text-sm text-gray-600 mb-3">
          {features.map((feature, index) => (
            <React.Fragment key={index}>
              {index > 0 && <span className="mx-2">•</span>}
              <span>{feature}</span>
            </React.Fragment>
          ))}
        </div>

        <div className="font-extrabold text-xl mb-3">{compactDescription}</div>

        {/* Car details with icons */}
        <div className="space-y-3 mb-4">
          <div className="flex items-center">
            <Navigation className="w-5 h-5 text-blue-500 mr-3" />
            <span className="w-32">Extra km fare</span>
            <span className="font-bold">{extraKmFare}</span>
          </div>
          <div className="flex items-center">
            <Droplet className="w-5 h-5 text-blue-500 mr-3" />
            <span className="w-32">Fuel Type</span>
            <span className="font-bold">{fuelType}</span>
          </div>
          <div className="flex items-center">
            <Clock className="w-5 h-5 text-blue-500 mr-3" />
            <span className="w-32">Cancellation</span>

            <span>
              <span className="text-blue-500 font-bold">Free</span>
              {" "}
              <span>till {cancellation} of departure</span>
            </span>
          </div>
        </div>

        {/* Button - now matches original width */}
        <div className="flex justify-start">
          <button
            className={`text-white py-3 px-16 rounded-md font-medium ${
              isAvailable
                ? "bg-[#465EA9] hover:bg-[#5572CC]"
                : "bg-gray-400 cursor-not-allowed"
            }`}
            onClick={() => isAvailable && onSelect(id)}
            disabled={!isAvailable}
          >
            {isAvailable ? "Select Car" : "Unavailable"}
          </button>
        </div>
      </div>

      {/* Unavailable tag - positioned at bottom left of card */}
      {!isAvailable ? (
        <span className="absolute bottom-6 left-6 px-3 py-1 text-xs bg-gray-200 rounded-md">
          Unavailable
        </span>
      ) : (
        <span className="absolute bottom-6 left-6 px-3 py-1 text-xs bg-gray-200 rounded-md">
          Available
        </span>
      )}
    </div>
  );
};

export default CarDetailsCard;
