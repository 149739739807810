import React from "react";
import ring from "../../assets/images/outer_ring.png";
import "../Footer/Footer.css";
import Swal from "sweetalert2";

const Banner = () => {
  return (
    <div className="relative w-full rounded-2xl bg-[#22262A] flex justify-center items-center py-5 overflow-hidden">
      <div className="absolute w-full h-full flex">
        <img
          src={ring}
          alt="Rotating Background"
          className="absolute spinwheelone"
          style={{
            height: "350px",
            width: "350px",
            top: "50%",
            right: "0%",
            transform: "translate(65%, -50%)",
            objectFit: "contain",
          }}
        />
        <img
          src={ring}
          alt="Rotating Background"
          className="absolute spinwheeltwo"
          style={{
            height: "350px",
            width: "350px",
            top: "50%",
            right: "0",
            transform: "translate(50%, -50%)",
            objectFit: "contain",
          }}
        />
      </div>
      <div className="relative w-full flex flex-col items-center my-8 z-1">
        <div className="font-mazzardbold text-[#E7E7E7] text-xl sm:text-3xl text-center my-2">
          Drive with Fusion Cab Today
        </div>
        <div className="text-[#E7E7E7] text-xs sm:text-xl text-center my-2">
          Get the app to explore the world of premium cars{" "}
        </div>
        <button
          className="my-2 rounded-full bg-[#fff] px-6 text-[#06080C] text-sm py-2 font-mazzardbold"
          onClick={() => {
            Swal.fire("Coming Soon", "The app will be available soon!", "info");
          }}
        >
          Download App
        </button>
      </div>
    </div>
  );
};

export default Banner;
