import React, { useEffect } from "react";
import HeroBg from "../../assets/images/hero-bg.png";
import HeroCar from "../../assets/images/herocar.png";
import "aos/dist/aos.css";
import AOS from "aos";

const HeroSection = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="w-full h-full flex lg:flex-row flex-col">
      <div className="lg:w-1/2 flex justify-center items-center w-full">
        <div className="h-full flex flex-col justify-center items-center lg:items-start">
          <div
            className="text-3xl sm:text-5xl mt-3 lg:text-6xl font-bold font-mazzardbold my-1"
            data-aos="fade-right"
          >
            Fusion Cab
          </div>
          <div
            className="my-1 text-2xl sm:text-2xl lg:text-3xl lg:text-left text-center w-4/5 font-mazzardextralight"
            data-aos="fade-right"
            data-aos-delay="200"
          >
            Get Ready to Redefine Chauffeur Driven Cab Rentals!
          </div>
          <div
            className="my-1 lg:text-left text-center"
            data-aos="fade-right"
            data-aos-delay="400"
          >
            We are Launching Soon a seamless, tech-enabled cab rental experience
            tailored to your needs
          </div>
        </div>
      </div>
      <div
        className="lg:w-1/2 h-full w-full flex justify-center items-center"
        data-aos="fade-left"
        data-aos-delay="600"
      >
        <div className="content w-full relative h-full">
          <img alt="" className="w-full" src={HeroBg} />
          <img
            alt=""
            src={HeroCar}
            style={{
              position: "absolute",
              right: "0%",
              bottom: "-100px",
            }}
            className="animate-slideInRight z-10"
          />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
