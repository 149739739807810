import React, { useEffect } from "react";
import AboutUsImg from "../../assets/images/aboutus.png";
import "aos/dist/aos.css";
import AOS from "aos";
import Swal from "sweetalert2";

const AboutUs = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="w-full h-full box-border p-4 flex justify-center items-center md:flex-row flex-col-reverse">
      <div
        className="md:w-2/5 w-full flex justify-center items-center h-full"
        data-aos="fade-right"
      >
        <div className="h-5/6">
          <img
            alt=""
            src={AboutUsImg}
            className="w-[90%] drop-shadow-2xl"
            style={{
              maxHeight: "550px",
            }}
          />
        </div>
      </div>
      <div className="md:w-3/5 w-full">
        <div className="w-full text-center flex flex-col gap-4">
          <div
            className="font-mazzardbold text-4xl md:text-5xl my-3"
            data-aos="fade-left"
          >
            About Us
          </div>
          <div className="my-2 text-left flex flex-col gap-2">
            {/* <p data-aos="fade-left" data-aos-delay="200">
              Welcome to Fusion Cab
            </p> */}
            <p data-aos="fade-left" data-aos-delay="400">
              At <strong> Fusion Cab</strong>, we are more than just a
              transportation service; we are a revolutionary force in the cab
              rental industry. Our mission is to bridge the gap between
              technology and transportation, offering a seamless, efficient, and
              customer-centric experience. With a focus on intercity travel,
              airport transfers, and hourly rentals, we provide a range of
              services tailored to meet the diverse needs of our clients.
            </p>
            <p data-aos="fade-left" data-aos-delay="600">
              Our cutting-edge tech platform connects businesses,
              intermediaries, and service providers in a way that ensures a
              smooth journey from booking to destination.
            </p>
            <p data-aos="fade-left" data-aos-delay="800">
              We believe in the power of innovation and are committed to
              transforming the commercial cab rental landscape into one that is
              organized, reliable, and accessible to all.
            </p>
          </div>
          <div
            className="my-2 text-left"
            data-aos="fade-left"
            data-aos-delay="1000"
          >
            <button
              className="rounded-3xl px-7 bg-[#06080C] text-white py-2 shadow-md"
              onClick={() => {
                Swal.fire(
                  "Coming Soon",
                  "The app will be available soon!",
                  "info"
                );
              }}
            >
              Download App
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
