import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import PrimaryLogo from "../../assets/images/primarylogo.png";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

const navigation = [
  { name: "Login", href: "login", current: false },
  {
    name: "Download App",
    href: "#",
    current: true,
    onClick: () =>
      Swal.fire("Coming Soon", "The app will be available soon!", "info"),
  },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function Navbar() {
  return (
    <Disclosure as="nav" className="bg-white" {...({} as any)}>
      <div className="py-3">
        <div className="relative flex h-16 items-center justify-between">
          <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
            <div className="flex shrink-0 items-center">
              <Link to={"/"}>
                <img
                  alt="Fusion Cab Logo"
                  src={PrimaryLogo}
                  className="h-14 w-auto"
                />
              </Link>
            </div>
          </div>
          <div className="relative inset-y-0 left-0 flex items-center sm:hidden">
            {/* Mobile menu button*/}
            <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md p-2 text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
              <span className="absolute -inset-0.5" />
              <span className="sr-only">Open main menu</span>
              <Bars3Icon
                aria-hidden="true"
                className="block size-6 group-data-[open]:hidden"
              />
              <XMarkIcon
                aria-hidden="true"
                className="hidden size-6 group-data-[open]:block"
              />
            </DisclosureButton>
          </div>
          <div className=" flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            <div className="hidden sm:ml-6 sm:block">
              <div className="flex space-x-4">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    onClick={item.onClick}
                    aria-current={item.current ? "page" : undefined}
                    className={classNames(
                      item.current
                        ? "bg-[#06080C] text-white  !rounded-3xl px-8 font-light"
                        : "text-[#06080C] font-bold hover:shadow-md !rounded-3xl px-8",
                      "rounded-md px-3 py-2 text-md font-lg"
                    )}
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <DisclosurePanel className="bg-white z-10 shadow-md rounded-sm sm:hidden inset-x-0 p-2 transition transform origin-top-right duration-1000">
        <div className="space-y-1 px-2 pb-3 pt-2">
          {navigation.map((item) => (
            <DisclosureButton
              key={item.name}
              as="Link"
              // href={item.href}
              to={item.href}
              onClick={item.onClick}
              aria-current={item.current ? "page" : undefined}
              className={classNames(
                item.current
                  ? "bg-gray-900 text-white"
                  : "text-gray-300 hover:bg-gray-700 hover:text-white",
                "block rounded-md px-3 py-2 text-base font-medium"
              )}
              {...({} as any)}
            >
              {item.name}
            </DisclosureButton>
          ))}
        </div>
      </DisclosurePanel>
    </Disclosure>
  );
}
