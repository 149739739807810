import React, { useState, useEffect } from "react";
import FirstImage from "../../assets/images/services/first.png";
import SecondImage from "../../assets/images/services/second.png";
import ThirdImage from "../../assets/images/services/third.png";
import "aos/dist/aos.css";
import AOS from "aos";

const Services = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const [isActive, setActive] = useState(1);

  const onChangeActive = (activenumber: number) => {
    setActive(activenumber);
  };

  return (
    <div className="w-full flex items-center justify-center">
      <div className="w-full">
        <div
          className="font-mazzardbold text-3xl md:text-5xl mt-5 text-center"
          data-aos="fade-up"
        >
          Services Offered
        </div>
        <div
          className="flex justify-center items-center my-8 md:flex-row flex-col gap-2"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <div
            className={`${
              isActive === 1 ? "bg-[#000]" : "bg-[#D9D9D9]"
            } flex justify-center items-center py-2 mx-2 rounded-md md:my-0 my-1 w-[210px]`}
          >
            <button
              className={`${
                isActive === 1 ? "text-[#fff]" : "text-[#000]"
              } text-xl`}
              onClick={() => onChangeActive(1)}
            >
              Intercity Travel
            </button>
          </div>
          <div
            className={`${
              isActive === 2 ? "bg-[#000]" : "bg-[#D9D9D9]"
            } flex justify-center items-center py-2 mx-2 rounded-md md:my-0 my-1 w-[210px]`}
          >
            <button
              className={`${
                isActive === 2 ? "text-[#fff]" : "text-[#000]"
              } text-xl`}
              onClick={() => onChangeActive(2)}
            >
              Airport Transfers
            </button>
          </div>
          <div
            className={`${
              isActive === 3 ? "bg-[#000]" : "bg-[#D9D9D9]"
            } flex justify-center items-center py-2 mx-2 rounded-md md:my-0 my-1 w-[210px]`}
          >
            <button
              className={`${
                isActive === 3 ? "text-[#fff]" : "text-[#000]"
              } text-xl`}
              onClick={() => onChangeActive(3)}
            >
              Hourly Rentals
            </button>
          </div>
        </div>
        {isActive === 1 && (
          <div
            className="w-full flex justify-center items-center"
            data-aos="fade-up"
            data-aos-delay="400"
          >
            <ul
              style={{ listStyle: "disc" }}
              className="w-[500px] flex flex-col"
            >
              <li className="my-4">
                <span className="font-mazzardbold">Oneway Trips:</span> Travel
                comfortably between cities with our one-way service. Ideal for
                those who need a quick and hassle-free transfer without the need
                to return to the original location.
              </li>
              <li className="my-4">
                <span className="font-mazzardbold">Roundtrip Journeys: </span>
                Enjoy a roundtrip journey with our professional drivers who will
                ensure a smooth ride to your destination and back. Perfect for
                business trips, family visits, or weekend getaways.
              </li>
            </ul>
          </div>
        )}
        {isActive === 2 && (
          <div
            className="w-full flex justify-center items-center"
            data-aos="fade-up"
            data-aos-delay="400"
          >
            <ul
              style={{ listStyle: "disc" }}
              className="w-[500px] flex flex-col"
            >
              <li className="my-4">
                <span className="font-mazzardbold">Airport Pickup:</span> Arrive
                at your destination stress-free with our punctual and convenient
                airport pickup service. Our drivers will be there to greet you,
                assist with your luggage, and ensure a swift journey to your
                desired location.
              </li>
              <li className="my-4">
                <span className="font-mazzardbold">Airport Drop Off:</span>{" "}
                Start your journey right with our airport drop-off service. We
                ensure you reach the airport on time with a comfortable and
                relaxed ride, giving you peace of mind before your flight.
              </li>
            </ul>
          </div>
        )}
        {isActive === 3 && (
          <div
            className="w-full flex justify-center items-center"
            data-aos="fade-up"
            data-aos-delay="400"
          >
            <ul
              style={{ listStyle: "disc" }}
              className="w-[500px] flex flex-col"
            >
              <li className="my-4">
                <span className="font-mazzardbold">
                  4 hours / 40 kilometers:
                </span>{" "}
                Perfect for short city trips, shopping, or quick meetings. Enjoy
                the convenience of a chauffeur-driven car at your disposal for
                up to 4 hours or 40 kilometers.
              </li>
              <li className="my-4">
                <span className="font-mazzardbold">
                  8 hours / 80 kilometers:
                </span>{" "}
                Suitable for day-long business meetings, city tours, or personal
                errands. This package offers you the flexibility of having a car
                for the entire day.
              </li>
              <li className="my-4">
                <span className="font-mazzardbold">
                  12 hours / 120 kilometers:
                </span>{" "}
                Ideal for those who need a car for extended periods. Whether
                it's a full day of sightseeing or multiple meetings, this option
                provides maximum convenience and flexibility.
              </li>
            </ul>
          </div>
        )}

        <div className="w-full flex justify-center items-center mt-10 md:flex-row flex-wrap gap-5 flex-col">
          <img
            alt="First"
            src={FirstImage}
            className="md:my-0 my-2"
            data-aos="fade-up"
            data-aos-delay="600"
          />
          <img
          alt="Second"
            src={SecondImage}
            className="md:my-0 my-2"
            data-aos="fade-up"
            data-aos-delay="800"
          />
          <img
            alt="Third"
            src={ThirdImage}
            className="md:my-0 my-2"
            data-aos="fade-up"
            data-aos-delay="1000"
          />
        </div>
      </div>
    </div>
  );
};

export default Services;
